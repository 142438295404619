import { useEffect } from "react";

function Connection({ reference, connection, from, output }) {
  useEffect(() => {
    reference[from].outputs[output].connect(
      reference[connection.to].inputs[connection.input]
    );

    return () => {
      if (reference[from] && reference[connection.to])
        reference[from].outputs[output].disconnect(
          reference[connection.to].inputs[connection.input]
        );
    };
  }, [reference, connection, from, output]);
  return null;
}

export default Connection;
