import React from "react";

import { useSelector } from "react-redux";

import OutputUi from "./OutputUi";

import SequenceOutUi from "./SequenceOutUi";

function ModuleConnectionsUi({ moduleId = "monoSynth1" }) {
  const sockets = useSelector((state) => state.patch.sockets[moduleId]);

  const outputs = !sockets.outputs
    ? null
    : Object.keys(sockets.outputs).map((outputName) => {
        return (
          <OutputUi
            key={moduleId + outputName}
            moduleId={moduleId}
            outputName={outputName}
          />
        );
      });

  const sequenceOuts = !sockets.sequenceOut ? null : (
    <SequenceOutUi key={moduleId + "sequence out"} moduleId={moduleId} />
  );

  return (
    <>
      {outputs}
      {sequenceOuts}
    </>
  );
}

export default ModuleConnectionsUi;
