import React from "react";

import { ParamSlider, ParamSelect, Panel } from "../../ui-components";

import { Grid } from "@material-ui/core";

function MonoSynthUi({ moduleId }) {
  return (
    <>
      <Grid container columns={8}>
        <Panel name="Oscillator">
          <ParamSelect
            menuItems={["square", "sawtooth"]}
            moduleId={moduleId}
            param={"waveform"}
            label="Type"
          />
          <ParamSlider
            marks
            moduleId={moduleId}
            param={"octave"}
            label="Octave"
            min={-2}
            max={2}
          />
          <ParamSlider
            moduleId={moduleId}
            param={"tune"}
            label="Tune"
            min={-100}
            max={100}
          />
          <ParamSlider
            moduleId={moduleId}
            param={"oscCv"}
            label="CV"
            min={0}
            max={100}
          />
        </Panel>

        <Panel name="Filter">
          <ParamSlider moduleId={moduleId} param={"cutoff"} label="Cutoff" />
          <ParamSlider moduleId={moduleId} param={"Q"} label="Q" />
          <ParamSlider
            moduleId={moduleId}
            param={"envMod"}
            label="Envelope Modulation"
          />
          <ParamSlider moduleId={moduleId} param={"filterCv"} label="CV" />
        </Panel>

        <Panel name="Envelope">
          <Grid container columns={8}>
            <Grid item xs={2}>
              <ParamSlider
                orientation="vertical"
                moduleId={moduleId}
                param="attack"
                label="A"
              />
            </Grid>

            <Grid item xs={2}>
              <ParamSlider
                orientation="vertical"
                moduleId={moduleId}
                param="decay"
                label="D"
              />
            </Grid>

            <Grid item xs={2}>
              <ParamSlider
                orientation="vertical"
                moduleId={moduleId}
                param="sustain"
                label="S"
              />
            </Grid>

            <Grid item xs={2}>
              <ParamSlider
                orientation="vertical"
                moduleId={moduleId}
                param="release"
                label="R"
              />
            </Grid>
          </Grid>
        </Panel>
      </Grid>
    </>
  );
}

export default MonoSynthUi;
