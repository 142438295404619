import React from "react";
import { useSelector } from "react-redux";
import Connection from "./Connection";

function Connections({ reference }) {
  const socketsObject = useSelector((state) => state.patch.sockets);

  //modulesWithOutputs is an array of moduleIds for all modules with an outputs object

  const modulesWithOutputs = Object.keys(socketsObject).filter(
    (moduleId) => socketsObject[moduleId].outputs
  );

  //a 2d array of outputNames (string). Each cell holds an array of the outputs for a module in modulesWithOutputs.
  const allOutputs = modulesWithOutputs.map((moduleId) => {
    return Object.keys(socketsObject[moduleId].outputs);
  });

  const connections = allOutputs.map((moduleOutputs, index) => {
    const moduleId = modulesWithOutputs[index];
    return moduleOutputs.map((outputName) => {
      return socketsObject[moduleId].outputs[outputName].map(
        (connection, connectionIndex) => (
          <Connection
            key={connectionIndex}
            reference={reference}
            from={moduleId}
            output={outputName}
            connection={connection}
          ></Connection>
        )
      );
    });
  });

  return <>{connections}</>;
}

export default Connections;
