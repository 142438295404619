export const patch = {
  transport: {
    isPlaying: false,
    tempo: "94",
  },
  patch: {
    sockets: {
      "Main Output": {
        inputs: ["Audio In"],
      },
      "Step Sequencer 1": {
        sequenceOut: ["Mono Synth 1"],
      },
      "Mono Synth 1": {
        inputs: ["filterIn", "oscCvIn", "filterCvIn"],
        outputs: {
          Output: [
            {
              to: "Main Output",
              input: "Audio In",
            },
          ],
        },
        sequenceIn: true,
        sequencedBy: "Step Sequencer 1",
      },
      "Gate Sequencer 1": {
        sequenceOut: ["Membrane Synth 1"],
      },
      "Membrane Synth 1": {
        outputs: {
          "Audio Out": [
            {
              to: "Main Output",
              input: "Audio In",
            },
          ],
        },
        sequenceIn: true,
        sequencedBy: "Gate Sequencer 1",
      },
      "Gate Sequencer 2": {
        sequenceOut: ["Noise Synth 1"],
      },
      "Noise Synth 1": {
        outputs: {
          "Audio Out": [
            {
              to: "Main Output",
              input: "Audio In",
            },
          ],
        },
        sequenceIn: true,
        sequencedBy: "Gate Sequencer 2",
      },
      "Gate Sequencer 3": {
        sequenceOut: ["Snare Synth 1"],
      },
      "Snare Synth 1": {
        outputs: {
          "Audio Out": [
            {
              to: "Main Output",
              input: "Audio In",
            },
          ],
        },
        sequenceIn: true,
        sequencedBy: "Gate Sequencer 3",
      },
    },
    modules: {
      "Step Sequencer 1": {
        moduleName: "Step Sequencer ",
      },
      "Mono Synth 1": {
        moduleName: "Mono Synth ",
      },
      "Gate Sequencer 1": {
        moduleName: "Gate Sequencer ",
      },
      "Membrane Synth 1": {
        moduleName: "Membrane Synth ",
      },
      "Gate Sequencer 2": {
        moduleName: "Gate Sequencer ",
      },
      "Noise Synth 1": {
        moduleName: "Noise Synth ",
      },
      "Gate Sequencer 3": {
        moduleName: "Gate Sequencer ",
      },
      "Snare Synth 1": {
        moduleName: "Snare Synth ",
      },
    },
    params: {
      "Step Sequencer 1": {
        pitches: [
          0, 4, 1, 2, 2, 0, 1, 1, 4, 0, 4, 5, 7, 6, 0, 2, 0, 4, 1, 2, 2, 0, 1,
          1, 4, 0, 4, 5, 7, 6, 0, 2,
        ],
        gates: [
          false,
          false,
          false,
          true,
          true,
          false,
          true,
          true,
          false,
          false,
          true,
          false,
          true,
          true,
          false,
          true,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
        ],
        length: 16,
        to: ["monoSynth1"],
        subdivision: "16n",
        probability: "100",
        currentStep: null,
        updateCurrentStep: false,
      },
      "Mono Synth 1": {
        octave: -2,
        tune: -1,
        waveform: "sawtooth",
        oscCv: 0,
        cutoff: 42,
        Q: 0,
        envMod: 0,
        filterCv: 0,
        attack: 10,
        decay: 0,
        sustain: 41,
        release: 74,
      },
      "Gate Sequencer 1": {
        gates: [
          true,
          false,
          false,
          false,
          true,
          false,
          false,
          false,
          true,
          false,
          false,
          false,
          true,
          false,
          false,
          false,
          true,
          false,
          false,
          false,
          true,
          false,
          false,
          false,
          true,
          false,
          false,
          false,
          true,
          false,
          false,
          false,
        ],
        length: 16,
        to: [""],
        subdivision: "16n",
        probability: 100,
        currentStep: null,
        updateCurrentStep: false,
      },
      "Membrane Synth 1": {
        tune: 0,
        decay: 29,
      },
      "Gate Sequencer 2": {
        gates: [
          false,
          true,
          true,
          true,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          true,
          false,
          false,
          false,
          true,
          false,
          false,
          false,
          true,
          false,
          false,
          false,
          true,
          false,
          false,
          false,
        ],
        length: "4",
        to: [""],
        subdivision: "16n",
        probability: "59",
        currentStep: null,
        updateCurrentStep: false,
      },
      "Noise Synth 1": {
        decay: 21,
        volume: -20,
      },
      "Gate Sequencer 3": {
        gates: [
          false,
          false,
          false,
          false,
          true,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          true,
          false,
          false,
          false,
          true,
          false,
          false,
          false,
          true,
          false,
          false,
          false,
          true,
          false,
          false,
          false,
          true,
          false,
          false,
          false,
        ],
        length: 16,
        to: [""],
        subdivision: "16n",
        probability: 100,
        currentStep: null,
        updateCurrentStep: false,
      },
      "Snare Synth 1": {
        tune: 0,
        decay: 26,
      },
    },
  },
  masterVolume: {
    volume: -12,
  },
};
