import { createSlice } from "@reduxjs/toolkit";

import { patch } from "../../patches/patch4";

const initialState = patch.patch.modules;

// const defaultState = {};

export const modulesSlice = createSlice({
  name: "patch/modules",
  initialState,
  reducers: {
    addModule: (state, { payload }) => {
      // expected payload: 'moduleName'
      if (!payload || typeof payload !== "string") return;

      // This function creates a unique ID in the format moduleName+Number eg 'Oscillator1'
      function createId(name, startsAt = 1) {
        let idNo = startsAt;
        while (state[name + idNo]) {
          idNo++;
        }
        return name + idNo;
      }

      const moduleId = createId(payload);
      state[moduleId] = { moduleName: payload };
    },
    deleteModule: (state, { payload }) => {
      delete state[payload];
    },
    deleteAll: (state) => (state = {}),
  },
});

export const { addModule, deleteModule, deleteAll } = modulesSlice.actions;

export default modulesSlice.reducer;
