import { useEffect } from "react";
import * as Tone from "tone";

import { useDispatch, useSelector } from "react-redux";

import { setParams } from "../../features/patch/paramsSlice";
import { registerSockets } from "../../features/patch/socketsSlice";

export const defaultParams = {
  type: "lowpass",
  frequency: 30,
  Q: 0,
  CV: 0,
};

function Filter({ reference, moduleId }) {
  const dispatch = useDispatch();
  const params = useSelector((state) => state.patch.params[moduleId]);
  const sockets = useSelector((state) => state.patch.sockets[moduleId]);

  if (!params)
    dispatch(setParams({ moduleId: moduleId, params: defaultParams }));

  useEffect(() => {
    if (!sockets) {
      dispatch(
        registerSockets({
          moduleId: moduleId,
          sockets: { inputs: ["Audio In", "CV"], outputs: { "Audio Out": [] } },
        })
      );
    }
  }, [dispatch, moduleId, sockets]);

  const { type, frequency, Q, CV } = params || defaultParams;

  useEffect(() => {
    reference[moduleId] = {
      filter: new Tone.Filter(200, "lowpass"),

      outputs: {
        "Audio Out": new Tone.Gain(1),
      },

      inputs: {
        "Audio In": null,
        CV: new Tone.Gain(0),
      },
    };

    reference[moduleId].inputs["Audio In"] = reference[moduleId].filter;

    reference[moduleId].filter.connect(
      reference[moduleId].outputs["Audio Out"]
    );

    reference[moduleId].inputs.CV.connect(reference[moduleId].filter.detune);

    //
    return () => {
      if (reference[moduleId]) {
        reference[moduleId].outputs["Audio Out"].dispose();
        reference[moduleId].filter.dispose();
        delete reference[moduleId];
      }
    };
  }, [reference, moduleId]);

  useEffect(() => {
    reference[moduleId].filter.frequency.rampTo(
      Tone.Frequency(frequency, "midi").transpose(24),
      "0.1"
    );
  }, [frequency, reference, moduleId]);

  useEffect(() => {
    reference[moduleId].filter.type = type;
  }, [type, reference, moduleId]);

  useEffect(() => {
    reference[moduleId].filter.Q.value = Q;
  }, [Q, reference, moduleId]);

  useEffect(() => {
    reference[moduleId].inputs.CV.gain.value = CV * 36;
  }, [CV, reference, moduleId]);

  return null;
}

export default Filter;
