import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Tone from "tone";

import { setParams } from "../../features/patch/paramsSlice";
import { registerSockets } from "../../features/patch/socketsSlice";

function halfNoteDuration(subdivision) {
  return parseInt(subdivision, 10) * 2 + "n";
}

const mtof = (m) => 440 * Math.pow(2, (m - 69) / 12);
const convertToTime = (i) => {
  return parseFloat(mtof(i * 1.27 - 115));
};

const defaultParams = {
  octave: 0,
  tune: 0,
  waveform: "sawtooth",
  oscCv: 0,
  cutoff: 40,
  Q: 0,
  envMod: 0,
  filterCv: 0,
  attack: 10,
  decay: 0,
  sustain: 10,
  release: 0,
};

function MonoSynthAudio({ reference, moduleId }) {
  const dispatch = useDispatch();
  const params = useSelector((state) => state.patch.params[moduleId]);

  if (!params)
    dispatch(setParams({ moduleId: moduleId, params: defaultParams }));

  const sockets = useSelector((state) => state.patch.sockets[moduleId]);

  useEffect(() => {
    if (!sockets) {
      dispatch(
        registerSockets({
          moduleId: moduleId,
          sockets: {
            inputs: ["filterIn", "oscCvIn", "filterCvIn"],
            outputs: { Output: [] },
            sequenceIn: true,
          },
        })
      );
    }
  }, [dispatch, moduleId, sockets]);

  const {
    octave,
    tune,
    waveform,
    oscCv,
    cutoff,
    Q,
    envMod,
    filterCv,
    attack,
    decay,
    sustain,
    release,
  } = params || defaultParams;

  useEffect(() => {
    reference[moduleId] = {
      synth: new Tone.MonoSynth({ volume: 0 }),
      inputs: {
        filterIn: undefined,
        oscCvIn: new Tone.Gain(2400),
        filterCvIn: new Tone.Gain(2400),
      },
      outputs: {
        Output: undefined,
      },
      sequencerMethod: function (note, subdivision, time) {
        this.synth.triggerAttackRelease(
          note,
          halfNoteDuration(subdivision),
          time
        );
      },
    };

    reference[moduleId].inputs.filterIn =
      reference[moduleId].synth.filter.input;

    reference[moduleId].outputs.Output = reference[moduleId].synth;

    reference[moduleId].inputs.oscCvIn.connect(
      reference[moduleId].synth.detune
    );
    reference[moduleId].inputs.filterCvIn.connect(
      reference[moduleId].synth.filter.detune
    );

    return () => {
      reference[moduleId].inputs.filterCvIn.dispose();
      reference[moduleId].inputs.oscCvIn.dispose();

      reference[moduleId].synth.dispose();

      delete reference[moduleId];
    };
  }, [reference, moduleId]);

  useEffect(() => {
    const tuneValue = octave * 1200 + tune * 12;
    reference[moduleId].synth.detune.value = tuneValue;
  }, [tune, octave, reference, moduleId]);

  useEffect(() => {
    reference[moduleId].synth.oscillator.type = waveform;
  }, [waveform, reference, moduleId]);

  useEffect(() => {
    reference[moduleId].inputs.oscCvIn.gain.value = oscCv * 48;
  }, [oscCv, reference, moduleId]);

  useEffect(() => {
    reference[moduleId].synth.filterEnvelope.baseFrequency = Tone.Frequency(
      cutoff,
      "midi"
    ).transpose(24);
  }, [cutoff, reference, moduleId]);

  useEffect(() => {
    reference[moduleId].synth.filter.Q.value = Q * 0.5;
  }, [Q, reference, moduleId]);

  useEffect(() => {
    reference[moduleId].synth.filterEnvelope.octaves = envMod * 0.08;
  }, [envMod, reference, moduleId]);

  useEffect(() => {
    reference[moduleId].inputs.filterCvIn.gain.value = filterCv * 48;
  }, [filterCv, reference, moduleId]);

  useEffect(() => {
    const attackTime = convertToTime(attack);
    reference[moduleId].synth.envelope.attack = attackTime;
    reference[moduleId].synth.filterEnvelope.attack = attackTime;
  }, [attack, reference, moduleId]);

  useEffect(() => {
    const decayTime = convertToTime(decay);
    reference[moduleId].synth.envelope.decay = decayTime;
    reference[moduleId].synth.filterEnvelope.decay = decayTime;
  }, [decay, reference, moduleId]);

  useEffect(() => {
    const sustainLevel = sustain * 0.01;
    reference[moduleId].synth.envelope.sustain = sustainLevel;
    reference[moduleId].synth.filterEnvelope.sustain = sustainLevel;
  }, [sustain, reference, moduleId]);

  useEffect(() => {
    const releaseTime = convertToTime(release);
    reference[moduleId].synth.envelope.release = releaseTime;
    reference[moduleId].synth.filterEnvelope.release = releaseTime;
  }, [release, reference, moduleId]);

  return null;
}

export default MonoSynthAudio;
