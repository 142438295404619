import React from "react";

import { ParamSlider, ParamSelect, Panel } from "../../ui-components";

function FilterUi({ moduleId }) {
  return (
    <Panel>
      <ParamSelect
        menuItems={[
          "lowpass",
          "highpass",
          "bandpass",
          // "lowshelf",
          // "highshelf",
          // "notch",
          // "allpass",
        ]}
        moduleId={moduleId}
        param={"type"}
        label="Type"
      />
      <ParamSlider
        moduleId={moduleId}
        param={"frequency"}
        label="Frequency"
        min={0}
        max={100}
      />
      <ParamSlider
        moduleId={moduleId}
        param={"Q"}
        label="Q"
        min={0}
        max={100}
      />

      <ParamSlider
        moduleId={moduleId}
        param={"CV"}
        label="CV"
        min={0}
        max={100}
      />
    </Panel>
  );
}

export default FilterUi;
