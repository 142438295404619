import { useEffect } from "react";
import { useSelector } from "react-redux";

import * as Tone from "tone";

function Transport() {
  const isPlaying = useSelector((state) => state.transport.isPlaying);
  const tempo = useSelector((state) => state.transport.tempo);

  useEffect(() => {
    isPlaying ? Tone.Transport.start() : Tone.Transport.stop();
  }, [isPlaying]);

  useEffect(() => {
    Tone.Transport.bpm.value = tempo;
  }, [tempo]);

  return null;
}

export default Transport;
