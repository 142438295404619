import { useEffect } from "react";
import * as Tone from "tone";

import { useDispatch, useSelector } from "react-redux";

import { setParams } from "../../features/patch/paramsSlice";
import { registerSockets } from "../../features/patch/socketsSlice";

export const defaultParams = {
  tune: 0,
  decay: 0,
};

const sliderToTime = (m) => 440 * Math.pow(2, (m * 1.5 - 69 - 124) / 12);

function SnareSynth({ reference, moduleId }) {
  const dispatch = useDispatch();
  const params = useSelector((state) => state.patch.params[moduleId]);
  const sockets = useSelector((state) => state.patch.sockets[moduleId]);

  if (!params)
    dispatch(setParams({ moduleId: moduleId, params: defaultParams }));

  useEffect(() => {
    if (!sockets) {
      dispatch(
        registerSockets({
          moduleId: moduleId,
          sockets: { outputs: { "Audio Out": [] }, sequenceIn: true },
        })
      );
    }
  }, [dispatch, moduleId, sockets]);

  const { tune, decay } = params || defaultParams;

  useEffect(() => {
    reference[moduleId] = {
      synth: {
        lowPass: new Tone.Filter({
          frequency: 11000,
        }),
        noiseSynth: new Tone.NoiseSynth({
          volume: -6,
          noise: {
            type: "pink",
            playbackRate: 3,
          },
          envelope: {
            attack: 0.001,
            decay: 0.13,
            sustain: 0,
            release: 0.03,
          },
        }),
        synth: new Tone.Synth({
          volume: -5,
          oscillator: {
            partials: [0, 2, 3, 4],
          },
          envelope: {
            attack: 0.001,
            decay: 0.17,
            sustain: 0,
            release: 0.05,
          },
        }),
      },
      outputs: { "Audio Out": new Tone.Gain(1) },
      sequencerMethod: function (pitch, subdivision, time) {
        this.synth.noiseSynth.triggerAttack(time);
        this.synth.synth.triggerAttack("Eb3", time);
      },
    };

    reference[moduleId].synth.noiseSynth.connect(
      reference[moduleId].synth.lowPass
    );

    reference[moduleId].synth.lowPass.connect(
      reference[moduleId].outputs["Audio Out"]
    );

    reference[moduleId].synth.synth.connect(
      reference[moduleId].outputs["Audio Out"]
    );

    return () => {
      reference[moduleId].outputs["Audio Out"].dispose();

      reference[moduleId].synth.noiseSynth.dispose();

      reference[moduleId].synth.synth.dispose();
    };
  }, [reference, moduleId]);

  useEffect(() => {
    reference[moduleId].synth.synth.detune.value = tune * 12;
  }, [tune, reference, moduleId]);

  useEffect(() => {
    const time = sliderToTime(decay);
    reference[moduleId].synth.synth.envelope.decay = time;
    reference[moduleId].synth.noiseSynth.envelope.decay = time;
  }, [decay, reference, moduleId]);

  return null;
}

export default SnareSynth;
