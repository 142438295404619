import React, { useRef } from "react";

import Transport from "./Transport";
import Modules from "./Modules";
import Connections from "./Connections";
import MainOutput from "./MainOutput";

function Audio() {
  const reference = useRef({});
  return (
    <>
      <Transport />
      <MainOutput reference={reference.current} />
      <Modules reference={reference.current} />
      <Connections reference={reference.current} />
    </>
  );
}

export default Audio;
