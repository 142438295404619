import { createSlice } from "@reduxjs/toolkit";

const initialState = { volume: -12 };

export const masterVolumeSlice = createSlice({
  name: "masterVolume",
  initialState,
  reducers: {
    setMasterVolume: (state, { payload }) => {
      state.volume = payload;
    },
  },
});

export const { setMasterVolume } = masterVolumeSlice.actions;

export default masterVolumeSlice.reducer;
