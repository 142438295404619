import { createSlice } from "@reduxjs/toolkit";

import { patch } from "../patches/patch4";

const initialState = patch.transport;

// const defaultState = {
//   isPlaying: false,
//   tempo: 140,
// };

export const transportSlice = createSlice({
  name: "transport",
  initialState,
  reducers: {
    toggleIsPlaying: (state) => {
      state.isPlaying = !state.isPlaying;
    },
    setTempo: (state, { payload }) => {
      state.tempo = payload;
    },
  },
});

export const { toggleIsPlaying, setTempo } = transportSlice.actions;

export default transportSlice.reducer;
