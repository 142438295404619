import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { connect, disconnect } from "../features/patch/socketsSlice";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Badge,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

function OutputUi({ moduleId = "monoSynth1", outputName }) {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const outputState = useSelector(
    (state) => state.patch.sockets[moduleId].outputs[outputName]
  );

  const connections = outputState.map((connection, index) => {
    const label = `${connection.to}: ${connection.input}`;

    return (
      <li key={index}>
        <Chip
          color="primary"
          sx={{ my: 0.25 }}
          label={label}
          onDelete={(e) =>
            dispatch(
              disconnect({ from: moduleId, output: outputName, index: index })
            )
          }
        ></Chip>
      </li>
    );
  });

  const form = (
    <NewConnectionForm
      onClose={handleClose}
      moduleId={moduleId}
      outputName={outputName}
    ></NewConnectionForm>
  );

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${moduleId}-connections-content`}
          id={`${moduleId}-connections-header`}
        >
          <Badge badgeContent={outputState.length} color="primary">
            <Typography>Connections from {outputName + "  "}</Typography>
          </Badge>
        </AccordionSummary>
        <AccordionDetails>
          <ul style={{ listStyle: "none" }}>{connections} </ul>
        </AccordionDetails>
        <AccordionActions>
          <Button onClick={handleClickOpen}>Add Connection</Button>
        </AccordionActions>
      </Accordion>
      <Dialog open={open} onClose={handleClose}>
        {form}
      </Dialog>
    </>
  );
}

export default OutputUi;

function NewConnectionForm({ onClose, moduleId, outputName }) {
  const dispatch = useDispatch();

  const sockets = useSelector((state) => state.patch.sockets);

  const toOptions = Object.keys(sockets).filter(
    (toModuleId, index) => sockets[toModuleId].inputs && toModuleId !== moduleId
  );

  const [to, setTo] = useState(toOptions[0]);
  const [input, setInput] = useState("");

  const toMenuItems = toOptions.map((toModuleId, index) => {
    return (
      <MenuItem key={index} value={toModuleId}>
        {toModuleId}
      </MenuItem>
    );
  });

  const inputMenuItems = to
    ? sockets[to].inputs.map((input, index) => {
        return (
          <MenuItem key={index} value={input}>
            {input}
          </MenuItem>
        );
      })
    : null;
  return (
    <>
      <DialogTitle>Add a connection</DialogTitle>
      <DialogContent>
        <Stack mt={2} spacing={2}>
          <TextField
            select
            fullWidth
            disabled
            value={moduleId}
            id="new-connection-from-select"
            label="From"
          >
            <MenuItem value={moduleId}>{moduleId}</MenuItem>
          </TextField>

          <TextField
            select
            fullWidth
            disabled
            value={outputName}
            id="new-connection-output-select"
            label="Output"
          >
            <MenuItem value={outputName}>{outputName}</MenuItem>
          </TextField>
          <TextField
            select
            fullWidth
            value={to}
            id="new-connection-to-module-select"
            label="To"
            onChange={(e) => {
              setTo(e.target.value);
              setInput("");
            }}
          >
            {toMenuItems}
          </TextField>

          <TextField
            fullWidth
            select
            value={input}
            id="new-connection-input-select"
            label="Input"
            onChange={(e) => setInput(e.target.value)}
          >
            {inputMenuItems}
          </TextField>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={(e) => {
            if (to && input) {
              dispatch(
                connect({
                  from: moduleId,
                  output: outputName,
                  to: to,
                  input: input,
                })
              );
              onClose();
            }
          }}
        >
          Connect
        </Button>
      </DialogActions>
    </>
  );
}
