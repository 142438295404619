import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  setParamAtIndex,
  setParam,
  randomiseSequence,
  shiftSequenceLeft,
  shiftSequenceRight,
} from "../../features/patch/paramsSlice";

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  Slider,
  Stack,
  TextField,
  styled,
} from "@material-ui/core";

import {
  Casino,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@material-ui/icons";

import { ParamSelect, ParamNumber, Panel } from "../../ui-components";

const StyledButton = styled(Button)({
  minWidth: 25,
  maxWidth: 25,
  padding: 10,
});

function StepSequencerUi({ moduleId }) {
  const dispatch = useDispatch();

  const drawsCurrentStep = false;

  const length = useSelector((state) => state.patch.params[moduleId].length);

  const currentStep = useSelector(
    (state) => state.patch.params[moduleId].currentStep
  );

  // The following useEffect can be used to visualize current step. May be too expensive

  useEffect(() => {
    if (drawsCurrentStep) {
      dispatch(
        setParam({
          moduleId: moduleId,
          param: "updateCurrentStep",
          value: true,
        })
      );
    }
    return () => {
      if (drawsCurrentStep) {
        dispatch(
          setParam({
            moduleId: moduleId,
            param: "updateCurrentStep",
            value: false,
          })
        );
        dispatch(
          setParam({ moduleId: moduleId, param: "currentStep", value: null })
        );
      }
    };
  }, [dispatch, moduleId, drawsCurrentStep]);

  const [lengthInputValue, setLengthInputValue] = useState(length);

  const handleLengthChange = (e) => {
    const inputValue = e.target.value;
    setLengthInputValue(inputValue);
    const lengthToDispatch =
      inputValue < 1 ? 1 : inputValue > 32 ? 32 : inputValue;

    dispatch(
      setParam({ moduleId: moduleId, param: "length", value: lengthToDispatch })
    );
  };
  const handleLengthBlur = (e) => {
    setLengthInputValue(length);
  };

  const dummyArray = [];

  for (let i = 0; i < length; i++) {
    dummyArray.push(i);
  }

  const steps = dummyArray.map((arrayItem, index) => (
    <Step
      key={index}
      moduleId={moduleId}
      index={index}
      isCurrentStep={currentStep === index}
    />
  ));

  return (
    <Panel>
      <Stack justifyContent="space-between" direction="row">
        <ParamSelect
          menuItems={["16n", "8n"]}
          moduleId={moduleId}
          param={"subdivision"}
          label="Clock"
        />
        {/* <ParamSelect
          menuItems={[
            "A",
            "Ab",
            "G",
            "F#",
            "F",
            "E",
            "Eb",
            "D",
            "C#",
            "C",
            "B",
            "Bb",
          ]}
          moduleId={moduleId}
          param={"root"}
          label="Root"
        /> */}

        <TextField
          value={lengthInputValue}
          type="number"
          inputProps={{ min: 1, max: 32 }}
          label="Length"
          id={moduleId + "length"}
          onChange={handleLengthChange}
          onBlur={handleLengthBlur}
        ></TextField>

        <ParamNumber
          moduleId={moduleId}
          param="probability"
          label="Probability"
          inputProps={{ min: 0, max: 100 }}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        ></ParamNumber>
      </Stack>
      <Stack justifyContent="space-between" direction="row">
        <RandomiseSequence moduleId={moduleId} />
        <IconButton onClick={() => dispatch(shiftSequenceLeft(moduleId))}>
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton onClick={() => dispatch(shiftSequenceRight(moduleId))}>
          <KeyboardArrowRight />
        </IconButton>
      </Stack>

      <Grid container columns={8}>
        {steps}
      </Grid>
    </Panel>
  );
}

export default StepSequencerUi;

function RandomiseSequence({ moduleId }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleClickOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <>
      <IconButton onClick={handleClickOpen}>
        <Casino />
      </IconButton>
      <RandomSequenceForm
        moduleId={moduleId}
        handleClose={handleClose}
        isOpen={isOpen}
      />
    </>
  );
}

function RandomSequenceForm({ moduleId, handleClose, isOpen }) {
  const dispatch = useDispatch();

  const [pitches, setPitches] = useState(true);
  const [gates, setGates] = useState(true);
  const [probability, setProbability] = useState(50);
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>Randomise Sequence</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    name="pitches"
                    checked={pitches}
                    onChange={() => setPitches(!pitches)}
                  />
                }
                label="Pitches"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="gates"
                    checked={gates}
                    onChange={() => setGates(!gates)}
                  />
                }
                label="Gates"
              />
            </FormGroup>
            <TextField
              disabled={!gates}
              value={probability}
              onChange={(e) => setProbability(e.target.value)}
              type="number"
              label={"Probability"}
              inputProps={{
                min: 0,
                max: 100,
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            ></TextField>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button
            onClick={(e) => {
              dispatch(
                randomiseSequence({
                  moduleId: moduleId,
                  pitches: pitches,
                  gates: gates,
                  probability: probability,
                })
              );
            }}
          >
            Randomise
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function Step({ moduleId, index, isCurrentStep }) {
  const dispatch = useDispatch();

  const pitch = useSelector(
    (state) => state.patch.params[moduleId].pitches[index]
  );
  const gate = useSelector(
    (state) => state.patch.params[moduleId].gates[index]
  );

  const isSelected = gate;

  return (
    <Grid key={index} item xs={1} sm={1}>
      <Stack py={2} spacing={2} alignItems="center" style={{ height: 200 }}>
        <Slider
          value={pitch}
          min={0}
          max={7}
          marks={true}
          color={isSelected ? "primary" : "secondary"}
          orientation="vertical"
          onChange={(e) => {
            dispatch(
              setParamAtIndex({
                moduleId: moduleId,
                param: "pitches",
                index: index,
                value: e.target.value,
              })
            );
          }}
        />
        <StyledButton
          size="medium"
          style={isCurrentStep ? { background: "red" } : {}}
          variant="contained"
          color={isSelected ? "primary" : "secondary"}
          onClick={(e) => {
            dispatch(
              setParamAtIndex({
                moduleId: moduleId,
                param: "gates",
                index: index,
                value: !gate,
              })
            );
          }}
        />
      </Stack>
    </Grid>
  );
}
