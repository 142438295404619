import React from "react";

import { ParamSlider, Panel } from "../../ui-components";

import { Grid } from "@material-ui/core";

function EnvelopeUi({ moduleId }) {
  return (
    <>
      <Grid container columns={8}>
        <Panel name="">
          <Grid container columns={8}>
            <Grid item xs={2}>
              <ParamSlider
                orientation="vertical"
                moduleId={moduleId}
                param="attack"
                label="A"
              />
            </Grid>

            <Grid item xs={2}>
              <ParamSlider
                orientation="vertical"
                moduleId={moduleId}
                param="decay"
                label="D"
              />
            </Grid>

            <Grid item xs={2}>
              <ParamSlider
                orientation="vertical"
                moduleId={moduleId}
                param="sustain"
                label="S"
              />
            </Grid>

            <Grid item xs={2}>
              <ParamSlider
                orientation="vertical"
                moduleId={moduleId}
                param="release"
                label="R"
              />
            </Grid>
          </Grid>
        </Panel>
      </Grid>
    </>
  );
}

export default EnvelopeUi;
