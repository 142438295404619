import OscillatorAudio from "./Oscillator/OscillatorAudio";
import LfoAudio from "./Lfo/LfoAudio";
import FilterAudio from "./Filter/FilterAudio";
import EnvelopeAudio from "./Envelope/EnvelopeAudio";

import MonoSynthAudio from "./MonoSynth/MonoSynthAudio";
import MembraneSynthAudio from "./MembraneSynth/MembraneSynthAudio";
import NoiseSynthAudio from "./NoiseSynth/NoiseSynthAudio";
import SnareSynthAudio from "./SnareSynth/SnareSynthAudio";

import StepSequencerAudio from "./StepSequencer/StepSequencerAudio";
import GateSequencerAudio from "./GateSequencer/GateSequencerAudio";
import VcaAudio from "./Vca/VcaAudio";

import OscillatorUi from "./Oscillator/OscillatorUi";
import LfoUi from "./Lfo/LfoUi";
import FilterUi from "./Filter/FilterUi";
import EnvelopeUi from "./Envelope/EnvelopeUi";
import VcaUi from "./Vca/VcaUi";

import MonoSynthUi from "./MonoSynth/MonoSynthUi";
import MembraneSynthUi from "./MembraneSynth/MembraneSynthUi";
import NoiseSynthUi from "./NoiseSynth/NoiseSynthUi";
import SnareSynthUi from "./SnareSynth/SnareSynthUi";

import StepSequencerUi from "./StepSequencer/StepSequencerUi";
import GateSequencerUi from "./GateSequencer/GateSequencerUi";

export const audioComponents = {
  "Oscillator ": OscillatorAudio,
  "Lfo ": LfoAudio,
  "Filter ": FilterAudio,
  "Envelope ": EnvelopeAudio,
  "Vca ": VcaAudio,

  "Mono Synth ": MonoSynthAudio,
  "Membrane Synth ": MembraneSynthAudio,
  "Noise Synth ": NoiseSynthAudio,
  "Snare Synth ": SnareSynthAudio,

  "Step Sequencer ": StepSequencerAudio,
  "Gate Sequencer ": GateSequencerAudio,
};

export const uiComponents = {
  "Oscillator ": OscillatorUi,
  "Lfo ": LfoUi,
  "Filter ": FilterUi,
  "Envelope ": EnvelopeUi,
  "Vca ": VcaUi,

  "Mono Synth ": MonoSynthUi,
  "Membrane Synth ": MembraneSynthUi,
  "Noise Synth ": NoiseSynthUi,
  "Snare Synth ": SnareSynthUi,

  "Step Sequencer ": StepSequencerUi,
  "Gate Sequencer ": GateSequencerUi,
};
