import { useEffect } from "react";
import * as Tone from "tone";

import { useSelector } from "react-redux";

function MainOutput({ reference, moduleId = "Main Output" }) {
  const masterVolume = useSelector((state) => state.masterVolume.volume);
  useEffect(() => {
    reference[moduleId] = {
      inputs: {
        "Audio In": new Tone.Volume(-12).toDestination(),
      },
    };
    return () => {
      reference[moduleId].inputs["Audio In"].dispose();
      delete reference[moduleId];
    };
  }, [reference, moduleId]);

  useEffect(() => {
    if (masterVolume !== -60) {
      reference[moduleId].inputs["Audio In"].mute = false;
      reference[moduleId].inputs["Audio In"].volume.rampTo(masterVolume, 0.1);
    } else reference[moduleId].inputs["Audio In"].mute = true;
  }, [reference, moduleId, masterVolume]);

  return null;
}

export default MainOutput;
