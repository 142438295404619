import React from "react";

import { useDispatch } from "react-redux";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Button,
  Grid,
  Typography,
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { uiComponents } from "../modules/index";
import ModuleConnectionsUi from "./ModuleConnectionsUi";
import { deleteModule } from "../features/patch/modulesSlice";

function ModuleUi({ moduleId, moduleName }) {
  const dispatch = useDispatch();
  function returnUiComponent() {
    const UiComponent = uiComponents[moduleName];

    return <UiComponent key={moduleId} moduleId={moduleId} />;
  }

  const handleClick = (e) => {
    dispatch(deleteModule(moduleId));
  };

  return (
    <Grid item xs={12} sm={6}>
      <Accordion elevation={5} TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${moduleId}-content`}
          id={`${moduleId}-header`}
        >
          <Typography color="primary"> {moduleId}</Typography>
        </AccordionSummary>
        <AccordionActions>
          <Button
            color="neutral"
            startIcon={<DeleteIcon />}
            onClick={handleClick}
          >
            Delete
          </Button>
        </AccordionActions>

        <AccordionDetails>{returnUiComponent()}</AccordionDetails>

        <ModuleConnectionsUi moduleId={moduleId} />
      </Accordion>
    </Grid>
  );
}

export default ModuleUi;
