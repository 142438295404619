import { useDispatch, useSelector } from "react-redux";

import { setParam } from "../features/patch/paramsSlice";

import {
  InputLabel,
  Stack,
  Slider,
  Typography,
  MenuItem,
  TextField,
} from "@material-ui/core";

export function ParamSlider({
  label,
  moduleId,
  param,
  orientation,
  index,
  ...props
}) {
  const dispatch = useDispatch();

  const sliderState = useSelector(
    (state) => state.patch.params[moduleId][param]
  );
  const sliderId = moduleId + param;

  function handleChange(e) {
    dispatch(
      setParam({
        moduleId: moduleId,
        param: param,
        value: e.target.value,
      })
    );
  }

  const sliderLabel = label ? (
    <InputLabel htmlFor={sliderId}>{label}</InputLabel>
  ) : null;

  return orientation !== "vertical" ? (
    <Stack minHeight={56}>
      {sliderLabel}
      <Slider
        value={sliderState}
        id={sliderId}
        onChange={handleChange}
        {...props}
      />
    </Stack>
  ) : (
    <Stack alignItems="center" height={224} minWidth={56} spacing={2}>
      {sliderLabel}
      <Slider
        orientation="vertical"
        value={sliderState}
        id={sliderId}
        onChange={handleChange}
        {...props}
      />
    </Stack>
  );
}

export function ParamSelect({
  menuItems = ["missing prop menuItmes: array"],
  label,
  moduleId,
  param,
  ...props
}) {
  const dispatch = useDispatch();

  const selectState = useSelector(
    (state) => state.patch.params[moduleId][param]
  );

  const menuItemComponents = menuItems.map((menuItem, index) => (
    <MenuItem key={menuItem} value={menuItem}>
      {menuItem}
    </MenuItem>
  ));

  const selectId = moduleId + param;

  return (
    <TextField
      select
      value={selectState}
      label={label}
      id={selectId}
      onChange={(e) => {
        dispatch(
          setParam({
            moduleId: moduleId,
            param: param,
            value: e.target.value,
          })
        );
      }}
      {...props}
    >
      {menuItemComponents}
    </TextField>
  );
}

export function ParamNumber({ label, moduleId, param, ...props }) {
  const dispatch = useDispatch();

  const selectState = useSelector(
    (state) => state.patch.params[moduleId][param]
  );

  const selectId = moduleId + param;

  return (
    <TextField
      type="number"
      value={selectState}
      label={label}
      id={selectId}
      onChange={(e) => {
        dispatch(
          setParam({
            moduleId: moduleId,
            param: param,
            value: e.target.value,
          })
        );
      }}
      {...props}
    />
  );
}

export function Panel({ children, name, ...props }) {
  const ifName = name ? (
    <Typography sx={{ fontSize: "h6.fontSize" }}>{name}</Typography>
  ) : null;
  return (
    <Stack spacing={1} minWidth={"100%"} {...props}>
      {ifName}
      {children}
    </Stack>
  );
}
