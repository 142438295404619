import React from "react";

import { ParamSlider, Panel } from "../../ui-components";

function NoiseSynthUi({ moduleId }) {
  return (
    <Panel>
      <ParamSlider
        moduleId={moduleId}
        param={"decay"}
        label="Decay"
        min={0}
        max={100}
      />
      <ParamSlider
        moduleId={moduleId}
        param={"volume"}
        label="Volume"
        min={-100}
        max={0}
      />
    </Panel>
  );
}

export default NoiseSynthUi;
