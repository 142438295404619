import { useEffect } from "react";
import * as Tone from "tone";

import { useDispatch, useSelector } from "react-redux";

import { setParams } from "../../features/patch/paramsSlice";
import { registerSockets } from "../../features/patch/socketsSlice";

const defaultParams = {
  waveform: "triangle",
  frequency: 30,
};

function Lfo({ reference, moduleId }) {
  const dispatch = useDispatch();
  const params = useSelector((state) => state.patch.params[moduleId]);
  const sockets = useSelector((state) => state.patch.sockets[moduleId]);

  if (!params)
    dispatch(setParams({ moduleId: moduleId, params: defaultParams }));

  useEffect(() => {
    if (!sockets) {
      dispatch(
        registerSockets({
          moduleId: moduleId,
          sockets: { outputs: { "Audio Out": [] } },
        })
      );
    }
  }, [dispatch, moduleId, sockets]);

  const { waveform, frequency } = params || defaultParams;

  useEffect(() => {
    reference[moduleId] = {
      lfo: new Tone.LFO({ frequency: 1, min: -1, max: 1 }).start(),

      outputs: {
        "Audio Out": new Tone.Gain(1),
      },
    };

    reference[moduleId].lfo.connect(reference[moduleId].outputs["Audio Out"]);

    //
    return () => {
      if (reference[moduleId]) {
        reference[moduleId].outputs["Audio Out"].dispose();
        reference[moduleId].lfo.dispose();
        delete reference[moduleId];
      }
    };
  }, [reference, moduleId]);

  useEffect(() => {
    reference[moduleId].lfo.frequency.value = Tone.Frequency(
      frequency,
      "midi"
    ).transpose(-72);
  }, [frequency, reference, moduleId]);

  useEffect(() => {
    reference[moduleId].lfo.type = waveform;
  }, [waveform, reference, moduleId]);

  return null;
}

export default Lfo;
