import React from "react";

import { useDispatch } from "react-redux";

import { deleteAll } from "../features/patch/modulesSlice";

import { Button } from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";

function DeleteAll({ ...props }) {
  const dispatch = useDispatch();
  const handleClick = (e) => {
    dispatch(deleteAll());
  };
  return (
    <Button {...props} startIcon={<DeleteIcon />} onClick={handleClick}>
      Delete All
    </Button>
  );
}

export default DeleteAll;
