import React from "react";

import { useDispatch, useSelector } from "react-redux";

import { setMasterVolume } from "./masterVolumeSlice";

import { Stack, Slider } from "@material-ui/core";
import { VolumeDown, VolumeUp } from "@material-ui/icons";

function MasterVolume() {
  const dispatch = useDispatch();
  const masterVolume = useSelector((state) => state.masterVolume.volume);
  return (
    <>
      <Stack minHeight={36} spacing={2} direction="row" alignItems="center">
        <VolumeDown />

        <Slider
          value={masterVolume}
          min={-60}
          max={0}
          id="masterVolume"
          orientation="horizontal"
          onChange={(e) => dispatch(setMasterVolume(e.target.value))}
        />
        <VolumeUp />
      </Stack>
    </>
  );
}

export default MasterVolume;
