import { useEffect } from "react";
import * as Tone from "tone";

import { useDispatch, useSelector } from "react-redux";

import { setParams } from "../../features/patch/paramsSlice";
import { registerSockets } from "../../features/patch/socketsSlice";

const defaultParams = {};

function Vca({ reference, moduleId }) {
  const dispatch = useDispatch();
  const params = useSelector((state) => state.patch.params[moduleId]);
  const sockets = useSelector((state) => state.patch.sockets[moduleId]);

  if (!params)
    dispatch(setParams({ moduleId: moduleId, params: defaultParams }));

  useEffect(() => {
    if (!sockets) {
      dispatch(
        registerSockets({
          moduleId: moduleId,
          sockets: {
            inputs: ["Audio In", "CV"],
            outputs: { "Audio Out": [] },
          },
        })
      );
    }
  }, [dispatch, moduleId, sockets]);

  useEffect(() => {
    reference[moduleId] = {
      vca: new Tone.Gain(0),
      inputs: {
        "Audio In": null,
        CV: null,
      },
      outputs: {
        "Audio Out": null,
      },
    };

    reference[moduleId].inputs["Audio In"] = reference[moduleId].vca;

    reference[moduleId].inputs["CV"] = reference[moduleId].vca.gain;

    reference[moduleId].outputs["Audio Out"] = reference[moduleId].vca;

    //
    return () => {
      if (reference[moduleId]) {
        reference[moduleId].vca.dispose();
        delete reference[moduleId];
      }
    };
  }, [reference, moduleId]);

  return null;
}

export default Vca;
