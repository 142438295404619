import React from "react";

import { useSelector } from "react-redux";

import ModuleUi from "./ModuleUi";

function Ui() {
  const modules = useSelector((state) => state.patch.modules);

  return Object.keys(modules).map((moduleId, index) => (
    <ModuleUi
      key={moduleId}
      moduleName={modules[moduleId].moduleName}
      moduleId={moduleId}
    />
  ));
}

export default Ui;
