import { createSlice } from "@reduxjs/toolkit";

import { patch } from "../../patches/patch4";

const initialState = patch.patch.params;

// const defaultState = {};

export const paramsSlice = createSlice({
  name: "patch/params",
  initialState,
  reducers: {
    setParams: (state, { payload }) => {
      state[payload.moduleId] = payload.params;
    },
    setParam: (state, { payload }) => {
      state[payload.moduleId][payload.param] = payload.value;
    },

    pushToParam: (state, { payload }) => {
      if (Array.isArray(state[payload.moduleId][payload.param])) {
        state[payload.moduleId][payload.param].push(payload.value);
      }
    },
    deleteFromParam: (state, { payload }) => {
      if (Array.isArray(state[payload.moduleId][payload.param])) {
        state[payload.moduleId][payload.param].filter(
          (item) => item !== payload.value
        );
      }
    },
    setParamAtIndex: (state, { payload }) => {
      state[payload.moduleId][payload.param][payload.index] = payload.value;
    },

    setArrayLength: (state, { payload }) => {
      if (Array.isArray(state[payload.moduleId][payload.param])) {
        state[payload.moduleId][payload.param].length = payload.value;
      }
    },
    randomiseSequence: (state, { payload }) => {
      if (payload.pitches) {
        state[payload.moduleId].pitches = state[payload.moduleId].pitches.map(
          () => Math.floor(Math.random() * 8)
        );
      }
      if (payload.gates) {
        state[payload.moduleId].gates = state[payload.moduleId].gates.map(
          () => Math.random() < payload.probability * 0.01
        );
      }
    },
    shiftSequenceLeft: (state, { payload }) => {
      if (state[payload].pitches) {
        const pitches = state[payload].pitches;
        const shiftedPitch = pitches.shift();
        pitches.splice(state[payload].length - 1, 0, shiftedPitch);
      }
      if (state[payload].gates) {
        const gates = state[payload].gates;
        const shiftedGate = gates.shift();
        gates.splice(state[payload].length - 1, 0, shiftedGate);
      }
    },
    shiftSequenceRight: (state, { payload }) => {
      if (state[payload].pitches) {
        const pitches = state[payload].pitches;
        const seqLength = state[payload].length;
        const lastPitch = pitches[seqLength - 1];
        pitches.unshift(lastPitch);
        pitches.splice(seqLength, 1);
      }
      if (state[payload].gates) {
        const gates = state[payload].gates;
        const seqLength = state[payload].length;
        const lastGate = gates[seqLength - 1];
        gates.unshift(lastGate);
        gates.splice(seqLength, 1);
      }
    },
  },
  extraReducers: {
    "patch/modules/deleteModule": (state, { payload }) => {
      Object.keys(state).forEach((moduleId) => {
        if (state[moduleId].to) {
          state[moduleId].to.filter((toModule) => toModule !== payload);
        }
      });
      delete state[payload];
    },
    "patch/modules/deleteAll": (state) => (state = {}),
  },
});

export const {
  setParam,
  setParamAtIndex,
  setParams,
  setArrayLength,
  randomiseSequence,
  shiftSequenceLeft,
  shiftSequenceRight,
} = paramsSlice.actions;

export default paramsSlice.reducer;
