import React, { useState } from "react";

import * as Tone from "tone";

import { useDispatch, useSelector } from "react-redux";

import { toggleIsPlaying, setTempo } from "./transportSlice";

import { TextField, IconButton } from "@material-ui/core";
import { PlayCircle, StopCircle } from "@material-ui/icons";

function Transport() {
  const dispatch = useDispatch();

  const tempo = useSelector((state) => state.transport.tempo);
  const isPlaying = useSelector((state) => state.transport.isPlaying);

  // local state used to validate tempo input. the input is restricted onBlur
  const [tempoInputValue, setTempoInputValue] = useState(tempo);

  const handleTempoChange = (e) => {
    const inputValue = e.target.value;
    setTempoInputValue(inputValue);
    const tempoToDispatch =
      inputValue < 1 ? 1 : inputValue > 999 ? 999 : inputValue;

    dispatch(setTempo(tempoToDispatch));
  };

  const handleTempoBlur = () => {
    setTempoInputValue(tempo);
  };

  return (
    <>
      <PlayButton
        isPlaying={isPlaying}
        onClick={(e) => {
          Tone.start();
          dispatch(toggleIsPlaying());
        }}
      ></PlayButton>
      <TempoControl
        value={tempoInputValue}
        onChange={handleTempoChange}
        onBlur={handleTempoBlur}
      ></TempoControl>
    </>
  );
}

export default Transport;

const PlayButton = ({ isPlaying, children, ...props }) => {
  const icon = isPlaying ? (
    <StopCircle fontSize="inherit" />
  ) : (
    <PlayCircle fontSize="inherit" />
  );

  return (
    <IconButton sx={{ fontSize: 56 }} color="primary" {...props}>
      {children}
      {icon}
    </IconButton>
  );
};

const TempoControl = ({ ...props }) => {
  return (
    <TextField
      type="number"
      inputProps={{ min: 1, max: 999 }}
      label="Tempo"
      id="tempo"
      {...props}
    ></TextField>
  );
};
