import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Tone from "tone";

import { setParams } from "../../features/patch/paramsSlice";
import { registerSockets } from "../../features/patch/socketsSlice";

function halfNoteDuration(subdivision) {
  return parseInt(subdivision, 10) * 2 + "n";
}

const mtof = (m) => 440 * Math.pow(2, (m - 69) / 12);
const convertToTime = (i) => {
  return parseFloat(mtof(i * 1.27 - 115));
};

const defaultParams = {
  attack: 10,
  decay: 0,
  sustain: 10,
  release: 0,
};

function EnvelopeAudio({ reference, moduleId }) {
  const dispatch = useDispatch();
  const params = useSelector((state) => state.patch.params[moduleId]);

  if (!params)
    dispatch(setParams({ moduleId: moduleId, params: defaultParams }));

  const sockets = useSelector((state) => state.patch.sockets[moduleId]);

  useEffect(() => {
    if (!sockets) {
      dispatch(
        registerSockets({
          moduleId: moduleId,
          sockets: {
            outputs: { Output: [] },
            sequenceIn: true,
          },
        })
      );
    }
  }, [dispatch, moduleId, sockets]);

  const { attack, decay, sustain, release } = params || defaultParams;

  useEffect(() => {
    reference[moduleId] = {
      envelope: new Tone.Envelope(),
      outputs: {
        Output: undefined,
      },
      sequencerMethod: function (note, subdivision, time) {
        this.envelope.triggerAttackRelease(halfNoteDuration(subdivision), time);
      },
    };
    reference[moduleId].outputs.Output = reference[moduleId].envelope;

    return () => {
      reference[moduleId].envelope.dispose();
      delete reference[moduleId];
    };
  }, [reference, moduleId]);

  useEffect(() => {
    const attackTime = convertToTime(attack);
    reference[moduleId].envelope.attack = attackTime;
  }, [attack, reference, moduleId]);

  useEffect(() => {
    const decayTime = convertToTime(decay);
    reference[moduleId].envelope.decay = decayTime;
  }, [decay, reference, moduleId]);

  useEffect(() => {
    const sustainLevel = sustain * 0.01;
    reference[moduleId].envelope.sustain = sustainLevel;
  }, [sustain, reference, moduleId]);

  useEffect(() => {
    const releaseTime = convertToTime(release);
    reference[moduleId].envelope.release = releaseTime;
  }, [release, reference, moduleId]);

  return null;
}

export default EnvelopeAudio;
