import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  addSequencerConnection,
  removeSequencerConnection,
} from "../features/patch/socketsSlice";

import {
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Badge,
  MenuItem,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

function SequenceOutUi({ moduleId = "StepSequencer 1" }) {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const outputState = useSelector(
    (state) => state.patch.sockets[moduleId].sequenceOut
  );

  const connections = outputState.map((toModuleId, index) => {
    const label = `${toModuleId}`;

    return (
      <li key={index}>
        <Chip
          variant="outlined"
          color="primary"
          sx={{ my: 0.25 }}
          label={label}
          onDelete={(e) =>
            dispatch(
              removeSequencerConnection({ from: moduleId, to: toModuleId })
            )
          }
        ></Chip>
      </li>
    );
  });

  const form = (
    <NewSequencerConnectionForm
      onClose={handleClose}
      moduleId={moduleId}
    ></NewSequencerConnectionForm>
  );

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${moduleId}-connections-content`}
          id={`${moduleId}-connections-header`}
        >
          <Badge badgeContent={outputState.length} color="primary">
            <Typography>Connections</Typography>
          </Badge>
        </AccordionSummary>

        <AccordionDetails>
          <ul style={{ listStyle: "none" }}>{connections} </ul>
        </AccordionDetails>
        <AccordionActions>
          <Button onClick={handleClickOpen}>Add Connection</Button>
        </AccordionActions>
      </Accordion>
      <Dialog open={open} onClose={handleClose}>
        {form}
      </Dialog>
    </>
  );
}

export default SequenceOutUi;

function NewSequencerConnectionForm({ onClose, moduleId }) {
  const dispatch = useDispatch();

  const sockets = useSelector((state) => state.patch.sockets);

  const [to, setTo] = useState("");

  const toMenuItems = Object.keys(sockets).map((toModuleId, index) => {
    return sockets[toModuleId].sequenceIn &&
      !sockets[toModuleId].sequencedBy ? (
      <MenuItem key={index} value={toModuleId}>
        {toModuleId}
      </MenuItem>
    ) : null;
  });

  return (
    <>
      <DialogTitle>Add a connection</DialogTitle>
      <DialogContent>
        <Stack mt={2} spacing={2}>
          <TextField
            select
            fullWidth
            value={moduleId}
            id="new-connection-from-select"
            label="From"
            disabled
          >
            <MenuItem value={moduleId}>{moduleId}</MenuItem>
          </TextField>

          <TextField
            select
            fullWidth
            value={to}
            id="new-connection-to-module-select"
            label="To"
            onChange={(e) => {
              setTo(e.target.value);
            }}
          >
            {toMenuItems}
          </TextField>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={(e) => {
            if (to) {
              dispatch(
                addSequencerConnection({
                  from: moduleId,
                  to: to,
                })
              );
              setTo("");
              onClose();
            }
          }}
        >
          Connect
        </Button>
      </DialogActions>
    </>
  );
}
