import React from "react";

import { ParamSlider, ParamSelect } from "../../ui-components";

import { Panel } from "../../ui-components/index";

function LfoUi({ moduleId }) {
  return (
    <Panel>
      <ParamSelect
        menuItems={["triangle", "square", "sawtooth"]}
        moduleId={moduleId}
        param={"waveform"}
        label="Type"
      />
      <ParamSlider
        moduleId={moduleId}
        param={"tune"}
        label="Tune"
        min={-100}
        max={100}
      />
      <ParamSlider
        marks
        moduleId={moduleId}
        param={"octave"}
        label="Octave"
        min={-2}
        max={2}
      />
      <ParamSlider
        moduleId={moduleId}
        param={"CV"}
        label="CV"
        min={0}
        max={100}
      />
    </Panel>
  );
}

export default LfoUi;
