import React from "react";

import { ParamSlider, Panel } from "../../ui-components";

function SnareSynthUi({ moduleId }) {
  return (
    <Panel>
      <ParamSlider
        moduleId={moduleId}
        param={"tune"}
        label="Tune"
        min={-100}
        max={100}
      />
      <ParamSlider
        moduleId={moduleId}
        param={"decay"}
        label="Decay"
        min={0}
        max={100}
      />
    </Panel>
  );
}

export default SnareSynthUi;
