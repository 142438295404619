import React from "react";
// import "./App.css";

import background from "./WoodTexture.png";

import Audio from "./audio-components/Audio";
import Ui from "./ui-components/Ui";

import { Typography, Stack, Paper, Grid } from "@material-ui/core";

import { grey } from "@material-ui/core/colors";

import AddModule from "./ui-components/AddModule";
import DeleteAll from "./ui-components/DeleteAll";
import Transport from "./features/Transport";
import MasterVolume from "./features/MasterVolume";

import { createTheme, ThemeProvider } from "@material-ui/core";

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#31cda8",
      dark: "#009b79",
      light: "#71ffd9",
    },
    secondary: {
      main: "#353535",
      dark: "#0f0f0f",
      light: "#5f5f5f",
    },
    neutral: {
      main: grey[400],
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Manrope, Roboto",
  },
});

const elevation = 5;

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <div
          style={{
            backgroundImage: `url(${background})`,
            margin: "auto",
            maxWidth: 1000,
            padding: 15,
          }}
          className="App"
        >
          <Paper padding={5}>
            <Grid p={2} container spacing={1}>
              <Grid item xs={12}>
                <Paper elevation={elevation} sx={{ px: 2, py: 1 }}>
                  <Stack direction="row" alignItems="center">
                    <Typography
                      color="primary"
                      sx={{
                        mr: "auto",
                        fontSize: "h4.fontSize",
                        textTransform: "uppercase",
                        letterSpacing: 4,
                      }}
                    >
                      Tone Easel
                    </Typography>
                    <Transport />
                  </Stack>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Paper elevation={elevation} sx={{ px: 2, py: 1 }}>
                  <MasterVolume />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper
                  elevation={elevation}
                  sx={{ py: 1, textAlign: "center" }}
                >
                  <AddModule />
                  <DeleteAll color="neutral" />
                </Paper>
              </Grid>
              <Ui />
            </Grid>
          </Paper>
        </div>
      </ThemeProvider>
      <Audio />
    </>
  );
}

export default App;
