import { createSlice } from "@reduxjs/toolkit";

import { patch } from "../../patches/patch4";

const initialState = patch.patch.sockets;

const defaultState = {
  "Main Output": { inputs: ["Audio In"] },
};

export const socketsSlice = createSlice({
  name: "patch/sockets",
  initialState,
  reducers: {
    registerSockets: (state, { payload }) => {
      // expected payload: 'moduleId: {inputs: [], outputs: {output1Name: [{connection object}, {connection object 2}]}'
      if (!payload || typeof payload !== "object") return;

      state[payload.moduleId] = payload.sockets;
    },
    connect: (state, { payload }) => {
      const newConnection = {
        to: payload.to,
        input: payload.input,
      };
      const connectionExists = state[payload.from].outputs[payload.output].some(
        (connection) =>
          JSON.stringify(connection) === JSON.stringify(newConnection)
      );
      if (!connectionExists)
        state[payload.from].outputs[payload.output].push(newConnection);
    },
    disconnect: (state, { payload }) => {
      state[payload.from].outputs[payload.output] = state[payload.from].outputs[
        payload.output
      ].splice((payload.index, 1));
    },
    addSequencerConnection: (state, { payload }) => {
      if (!state[payload.from].sequenceOut.includes(payload.to)) {
        state[payload.from].sequenceOut.push(payload.to);

        state[payload.to].sequencedBy = payload.from;
      }
    },
    removeSequencerConnection: (state, { payload }) => {
      state[payload.from].sequenceOut = state[payload.from].sequenceOut.filter(
        (toModule) => toModule !== payload.to
      );

      delete state[payload.to].sequencedBy;
    },
  },
  extraReducers: {
    "patch/modules/deleteModule": (state, { payload }) => {
      //delete all connections from the deleted Module
      if (state[payload] && state[payload].outputs) {
        Object.keys(state[payload].outputs).forEach((output) => {
          state[payload].outputs[output] = [];
        });
      }
      if (
        state[payload] &&
        (state[payload].inputs ||
          state[payload].sequenceIn ||
          state[payload].sequenceOut)
      ) {
        Object.keys(state).forEach((moduleId) => {
          //delete all audio connections to the deleted Module
          if (state[moduleId].outputs && moduleId !== payload) {
            Object.keys(state[moduleId].outputs).forEach((output) => {
              state[moduleId].outputs[output] = state[moduleId].outputs[
                output
              ].filter((connection) => connection.to !== payload);
            });
          }
          //delete sequencer connections to the deleted Module
          if (state[moduleId].sequenceOut && moduleId !== payload) {
            state[moduleId].sequenceOut = state[moduleId].sequenceOut.filter(
              (toModuleId) => toModuleId !== payload
            );
          }

          // delete sequencedBy is if deleted module
          if (state[moduleId].sequencedBy === payload) {
            delete state[moduleId].sequencedBy;
          }
        });
      }
      delete state[payload];
    },
    "patch/modules/deleteAll": (state) => (state = defaultState),
  },
});

export const {
  registerSockets,
  connect,
  disconnect,
  addSequencerConnection,
  removeSequencerConnection,
} = socketsSlice.actions;

export default socketsSlice.reducer;
