import { useEffect } from "react";
import * as Tone from "tone";

import { useDispatch, useSelector } from "react-redux";

import { setParams } from "../../features/patch/paramsSlice";
import { registerSockets } from "../../features/patch/socketsSlice";

export const defaultParams = {
  decay: 0,
  volume: -12,
};

const sliderToTime = (m) => 440 * Math.pow(2, (m * 1.5 - 69 - 124) / 12);

function NoiseSynth({ reference, moduleId }) {
  const dispatch = useDispatch();
  const params = useSelector((state) => state.patch.params[moduleId]);
  const sockets = useSelector((state) => state.patch.sockets[moduleId]);

  if (!params)
    dispatch(setParams({ moduleId: moduleId, params: defaultParams }));

  useEffect(() => {
    if (!sockets) {
      dispatch(
        registerSockets({
          moduleId: moduleId,
          sockets: { outputs: { "Audio Out": [] }, sequenceIn: true },
        })
      );
    }
  }, [dispatch, moduleId, sockets]);

  const { decay, volume } = params || defaultParams;

  useEffect(() => {
    reference[moduleId] = {
      synth: new Tone.NoiseSynth({ volume: -12, envelope: { sustain: 0 } }),

      outputs: {
        "Audio Out": new Tone.Gain(1),
      },
      sequencerMethod: function (pitch, subdivision, time) {
        this.synth.triggerAttack(time);
      },
    };

    reference[moduleId].synth.connect(reference[moduleId].outputs["Audio Out"]);

    //
    return () => {
      if (reference[moduleId]) {
        reference[moduleId].outputs["Audio Out"].dispose();
        reference[moduleId].synth.dispose();
        delete reference[moduleId];
      }
    };
  }, [reference, moduleId]);

  useEffect(() => {
    const time = sliderToTime(decay);
    reference[moduleId].synth.envelope.decay = time;
  }, [decay, reference, moduleId]);

  useEffect(() => {
    reference[moduleId].synth.volume.value = volume;
  }, [volume, reference, moduleId]);

  return null;
}

export default NoiseSynth;
