import React from "react";

import { ParamSlider, ParamSelect, Panel } from "../../ui-components";

function LfoUi({ moduleId }) {
  return (
    <Panel>
      <ParamSelect
        menuItems={["triangle", "square", "sawtooth"]}
        moduleId={moduleId}
        param={"waveform"}
        label="Type"
      />
      <ParamSlider
        moduleId={moduleId}
        param={"frequency"}
        label="Frequency"
        min={0}
        max={100}
      />
    </Panel>
  );
}

export default LfoUi;
