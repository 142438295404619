import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import transportReducer from "../features/transportSlice";
import masterVolumeReducer from "../features/masterVolumeSlice";

import modulesReducer from "../features/patch/modulesSlice";
import socketsReducer from "../features/patch/socketsSlice";
import paramsReducer from "../features/patch/paramsSlice";

export const store = configureStore({
  reducer: {
    transport: transportReducer,

    patch: combineReducers({
      sockets: socketsReducer,
      modules: modulesReducer,
      params: paramsReducer,
    }),
    masterVolume: masterVolumeReducer,
  },
});
