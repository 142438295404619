import React from "react";
import { useSelector } from "react-redux";

import { audioComponents } from "../modules/index";

function returnAudioComponent(moduleName, moduleId, reference) {
  const AudioComponent = audioComponents[moduleName];

  return (
    <AudioComponent key={moduleId} moduleId={moduleId} reference={reference} />
  );
}

function Modules({ reference }) {
  const modulesObject = useSelector((state) => state.patch.modules);

  const audioComponents = Object.keys(modulesObject).map((moduleId) =>
    returnAudioComponent(
      modulesObject[moduleId].moduleName,
      moduleId,
      reference
    )
  );
  return <>{audioComponents}</>;
}

export default Modules;
