import { useEffect } from "react";
import * as Tone from "tone";

import { useDispatch, useSelector } from "react-redux";

import { setParams } from "../../features/patch/paramsSlice";
import { registerSockets } from "../../features/patch/socketsSlice";

export const defaultParams = {
  waveform: "triangle",
  tune: 0,
  octave: 0,
  CV: 0,
};

function Oscillator({ reference, moduleId }) {
  const dispatch = useDispatch();
  const params = useSelector((state) => state.patch.params[moduleId]);
  const sockets = useSelector((state) => state.patch.sockets[moduleId]);

  if (!params)
    dispatch(setParams({ moduleId: moduleId, params: defaultParams }));

  useEffect(() => {
    if (!sockets) {
      dispatch(
        registerSockets({
          moduleId: moduleId,
          sockets: {
            inputs: ["CV"],
            outputs: { "Audio Out": [] },
            sequenceIn: true,
          },
        })
      );
    }
  }, [dispatch, moduleId, sockets]);

  const { waveform, tune, octave, CV } = params || defaultParams;

  useEffect(() => {
    reference[moduleId] = {
      oscillator: new Tone.Oscillator({
        frequency: 440,
      }).start(),

      inputs: {
        CV: new Tone.Gain(0),
      },
      outputs: {
        "Audio Out": new Tone.Gain(1),
      },
      sequencerMethod: function (note, subdivision, time) {
        this.oscillator.frequency.value = note;
      },
    };

    reference[moduleId].oscillator.connect(
      reference[moduleId].outputs["Audio Out"]
    );

    reference[moduleId].inputs.CV.connect(
      reference[moduleId].oscillator.detune
    );

    //
    return () => {
      if (reference[moduleId]) {
        reference[moduleId].outputs["Audio Out"].dispose();
        reference[moduleId].oscillator.dispose();
        delete reference[moduleId];
      }
    };
  }, [reference, moduleId]);

  useEffect(() => {
    reference[moduleId].oscillator.detune.value = tune * 12 + octave * 1200;
  }, [tune, octave, reference, moduleId]);

  useEffect(() => {
    reference[moduleId].oscillator.type = waveform;
  }, [waveform, reference, moduleId]);

  useEffect(() => {
    reference[moduleId].inputs.CV.gain.value = CV * 36;
  }, [CV, reference, moduleId]);

  return null;
}

export default Oscillator;
