import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Tone from "tone";

import useThrottledEffect from "use-throttled-effect";

import { setParams, setParam } from "../../features/patch/paramsSlice";
import { registerSockets } from "../../features/patch/socketsSlice";

// set up default parameters
function createGatesArray(length = 16) {
  const gatesArray = [];
  for (let i = 0; i < length; i++) {
    gatesArray[i] = i % 4 === 0;
  }
  return gatesArray;
}
const defaultParams = {
  gates: createGatesArray(32),
  length: 16,
  to: [""],
  subdivision: "16n",
  probability: 100,
  currentStep: null,
  updateCurrentStep: false,
};

function GateSequencerAudio({ moduleId, reference }) {
  // Use dispatch to set ucrrent step in state for easy access
  const dispatch = useDispatch();

  const params = useSelector((state) => state.patch.params[moduleId]);

  if (!params)
    dispatch(setParams({ moduleId: moduleId, params: defaultParams }));

  const { gates, length, subdivision, probability, updateCurrentStep } =
    params || defaultParams;

  const sockets = useSelector((state) => state.patch.sockets[moduleId]);

  const sequenceOut =
    sockets && sockets.sequenceOut ? sockets.sequenceOut : undefined;

  useEffect(() => {
    if (!sockets) {
      dispatch(
        registerSockets({
          moduleId: moduleId,
          sockets: {
            sequenceOut: [],
          },
        })
      );
    }
  }, [dispatch, moduleId, sockets]);
  //

  useEffect(() => {
    reference[moduleId] = {
      sequence: null,
      steps: null,
    };
    reference[moduleId].sequence = new Tone.Sequence(
      (time, note) => {
        if (sequenceOut)
          sequenceOut.forEach((toModule) =>
            reference[toModule].sequencerMethod(note, subdivision, time)
          );
      },
      [],
      subdivision
    ).start(0);

    reference[moduleId].steps = new Tone.Sequence(
      (time, step) => {
        if (updateCurrentStep) {
          Tone.Draw.schedule(() => {
            dispatch(
              setParam({
                moduleId: moduleId,
                param: "currentStep",
                value: step,
              })
            );
          }, time);
        }
      },
      [],
      subdivision
    ).start(0);

    return () => {
      reference[moduleId].sequence.dispose();
      reference[moduleId].steps.dispose();
    };
  }, [
    reference,
    moduleId,
    dispatch,
    subdivision,
    sequenceOut,
    updateCurrentStep,
  ]);

  useThrottledEffect(
    () => {
      const sequence = gates
        .slice(0, length)
        .map((gate, index) => (gate ? "C3" : null));

      reference[moduleId].sequence.events = sequence;

      // a new sequnce needs to be calculated when cb

      reference[moduleId].steps.events = sequence.map((note, index) => index);

      if (probability) {
        reference[moduleId].sequence.probability = probability * 0.01;
      }
    },
    500,
    [
      reference,
      moduleId,
      length,
      gates,
      probability,
      subdivision,
      sequenceOut,
      updateCurrentStep,
    ]
  );

  return null;
}

export default GateSequencerAudio;
