import React, { useState } from "react";

import { useDispatch } from "react-redux";
import { audioComponents } from "../modules";
import { addModule } from "../features/patch/modulesSlice";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";

function AddModule() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const form = <NewModuleForm onClose={handleClose}></NewModuleForm>;

  return (
    <>
      <Button startIcon={<AddIcon />} onClick={handleClickOpen}>
        Add Module
      </Button>
      <Dialog open={open} onClose={handleClose}>
        {form}
      </Dialog>
    </>
  );
}

export default AddModule;

function NewModuleForm({ onClose }) {
  const dispatch = useDispatch();

  const [module, setModule] = useState("");

  const moduleOptions = Object.keys(audioComponents).map(
    (moduleName, index) => (
      <MenuItem key={index} value={moduleName}>
        {moduleName}
      </MenuItem>
    )
  );

  return (
    <>
      <DialogTitle>Add a module</DialogTitle>
      <DialogContent>
        <Stack mt={2} spacing={2}>
          <FormControl fullWidth>
            <InputLabel id="new-module-select-label">Module</InputLabel>
            <Select
              value={module}
              labelId="new-module-select-label"
              id="new-module-select"
              label="Module"
              onChange={(e) => setModule(e.target.value)}
            >
              {moduleOptions}
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={(e) => {
            if (module) {
              dispatch(addModule(module));
              onClose();
            }
          }}
        >
          Add
        </Button>
      </DialogActions>
    </>
  );
}
